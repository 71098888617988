import { handleErrorWithSentry, Replay } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';

import { PUBLIC_SENTRY_DSN } from '$env/static/public';

Sentry.init({
  dsn: PUBLIC_SENTRY_DSN,
  tracesSampleRate: 0.01, // Reduced to save resources

  // Reduce replay sampling rates to save resources
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 0.1,

  integrations: [new Replay()],
  enabled: process.env.NODE_ENV === 'production',

  // Ignore common client-side errors
  ignoreErrors: [
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    /Failed to fetch/,
    /ChunkLoadError/,
    /Loading chunk .* failed/,
    /NetworkError/,
    'ResizeObserver loop limit exceeded', // Common browser error
    'ResizeObserver loop completed with undelivered notifications', // Another common browser error
    'Script error.', // Cross-origin errors
    'Request failed with status code' // Axios/fetch errors
  ],

  beforeSend(event) {
    // Don't send events for navigation errors
    if (event.exception) {
      const errorMessage = event.exception.values?.[0]?.value || '';

      // Filter out expected errors
      if (
        errorMessage.includes('404') ||
        errorMessage.includes('401') ||
        errorMessage.includes('403') ||
        errorMessage.includes('Failed to load') ||
        errorMessage.includes('Loading chunk') ||
        errorMessage.includes('Network request failed') ||
        errorMessage.includes('Navigation cancelled') ||
        errorMessage.includes('interrupted by another navigation')
      ) {
        return null;
      }
    }

    // Don't track client-side routing errors
    if (event.request?.url?.includes('/_app/immutable/')) {
      return null;
    }

    return event;
  }
});

// Custom error handler for client-side errors
const myClientErrorHandler = ({ error, event }) => {
  // Only report actual errors, not navigation or loading issues
  if (error instanceof Error) {
    const errorMessage = error.message.toLowerCase();
    const isExpectedError =
      errorMessage.includes('404') ||
      errorMessage.includes('401') ||
      errorMessage.includes('403') ||
      errorMessage.includes('navigation') ||
      errorMessage.includes('loading failed') ||
      errorMessage.includes('network');

    if (isExpectedError) {
      // Just log locally for debugging
      console.log('Expected client error:', error.message);
      return;
    }
  }
};

export const handleError = handleErrorWithSentry(myClientErrorHandler);
